<template>
    <div>
        <SectionHead />
        <div class="container-fluid container-head" style="position: relative;bottom: 75px;">
            <div class="row justify-content-center">
                <div class="col-md-8 d-flex flex-column align-self-stretch">
                    <FrequentQuestion/>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import SectionHead from '@/components/order/Section.vue'
import FrequentQuestion from '../components/FrequentQuestion.vue'
export default {
    name: 'index',
    components: {
        SectionHead,FrequentQuestion
    },
    mounted() {
    },
    data() {
        return {
        }
    },
    computed: {

    }
}
</script>
  
<style scoped></style>
  