<template>
<div class="row">
    <div class="col-md-6">
        <div class="card section-item-order-data-container p-0 text-center lift mb-2" style="background-color: #8b8b8b ;cursor:pointer;color:white;padding-bottom: 2px !important">
            <label class="btn btn-primary btn-file-upload m-0" id="inputFile" style="margin:auto;background-color: #8b8b8b;font-weight: 500;padding-top: 10px;">
                Sub&iacute; tus archivos <i class="fa-solid fa-upload"></i>
                <input type="file" multiple id="fileInput" ref="fileInput" @change="this.handleFileChange" accept="application/pdf" /> <br>
                <a style="font-size: 15px;font-weight:400;">No te olvides de sumarlos al carrito.</a>
            </label>
        </div>
    </div>
    <div class="col-md-3">
        <div class="card section-item-order-data-container lift p-0 mb-2" style="background-color: #0A6ACB;cursor:pointer;" @click="this.addtoOrder()">
            <div class="col-md-12 text-center mt-1" style="color:white;font-size: 20px;  font-weight: 500;">
                Subtotal: <label v-if="this.totalSubOrder() != 0 && this.hoja.length>1 && this.cantidad>0 &&  this.formato.length>1 && this.calidad.length>1 && this.faz.length>1 && this.tipoPapel.length>1 && this.terminado.length>1"> {{this.totalSubOrder()}}</label> <label v-else> $0</label>
                <br>
                <label class="btn btn-pay" style="background-color: #0A6ACB;color:white">
                    Añadir <i class="fa-solid fa-cart-plus"></i>
                </label>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="card section-item-order-data-container lift p-0 mb-2" style="background-color: #95c123;cursor:pointer;" @click="this.previewOrder()">
            <div class="col-md-12 text-center mt-1" style="color:white;font-size: 20px;  font-weight: 500;">
                Total: <label v-if="this.totalOrder() != 0"> {{this.totalOrder()}}</label> <label v-else> $0</label> <br>
                <label class="btn btn-pay">
                    Pagar <i class="fa-solid fa-credit-card"></i>
                </label>
            </div>
        </div>
    </div>
</div>

<div class="section-item-file-data-container" style="margin-top:25px;min-height:553px;background-color: inherit;">
    <div class="row">
        <div v-for="data in this.pdfSrc" :key="data" class="col-md-4 mt-4 text-center">
            <div class="card">
                <div class="row justify-content-center pdf-height">
                    <div class="col-md-12 text-center">
                        <label class="mt-2 price-file w-100" v-if="this.hoja.length>1 && this.cantidad>0 &&  this.formato.length>1 && this.calidad.length>1 && this.faz.length>1 && this.tipoPapel.length>1 && this.terminado.length>1">Precio {{this.formatearMoneda(data.price * this.cantidad) }}</label>
                        <label class="mt-2 price-file w-100" v-else>Sin cotizar</label>
                        <hr class="mt-2 mb-0">
                    </div>

                    <!-- formato 1 x carilla -->
                    <div class="col-md-12 mb-3" v-if="formato == 'FH01'">
                        <div class="container-pdf pdf-page">
                            <pdfvuer ref="pdfViewer1" :src="this.createPDF(data)" :page="page1" />
                        </div>
                    </div>
                    <!-- formato 2 x carilla horizontal-->
                    <div class="col-md-12 mb-3" v-if="formato == 'FH02'">
                        <div class="pdf-container pdf-flex">
                            <div class="pdf-page">
                                <pdfvuer ref="pdfViewer1" :src="this.createPDF(data)" :page="page1" />
                            </div>
                            <div class="pdf-page">
                                <pdfvuer ref="pdfViewer1" :src="this.createPDF(data)" :page="page2" />
                            </div>
                        </div>
                    </div>
                    <!-- formato 2 x carilla vertical-->
                    <div class="col-md-12 mb-3" v-if="formato == 'FH04'">
                        <div class="container-pdf pdf-page-vertical">
                            <pdfvuer ref="pdfViewer1" :src="this.createPDF(data)" :page="page1" />
                            <pdfvuer ref="pdfViewer1" :src="this.createPDF(data)" :page="page2" />
                        </div>
                    </div>

                    <div class="col-md-12 mb-3" v-if="formato == 'FH03'">
                        <div class="pdf-container pdf-flex justify-content-center">
                            <div class="pdf-page-vertical">
                                <pdfvuer ref="pdfViewer1" :src="this.createPDF(data)" :page="page1" />
                            </div>
                            <div class="pdf-page-vertical">
                                <pdfvuer ref="pdfViewer1" :src="this.createPDF(data)" :page="page2" />
                            </div>
                        </div>
                        <div class="pdf-container pdf-flex justify-content-center">
                            <div class="pdf-page-vertical">
                                <pdfvuer ref="pdfViewer1" :src="this.createPDF(data)" :page="page3" />
                            </div>
                            <div class="pdf-page-vertical">
                                <pdfvuer ref="pdfViewer1" :src="this.createPDF(data)" :page="page4" />
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-12 pb-2">
                    <label class="price-file w-100">P&aacute;ginas: {{ data.pages }}</label>
                </div>
            </div>
            <div class="col-md-12">
                <a class="btn btn-primary btn-file-upload mt-2 lift mb-1  icon-pdf-view" @click="previewPdf(data)">
                    <i class="fa-solid fa-eye"></i>
                </a>
                <a class="btn btn-primary btn-file-upload mt-2 lift mb-1 ms-2 icon-pdf-trash" @click="removeFileCotizacion(data)">
                    <i class="fa-solid fa-trash"></i>
                </a>
            </div>
        </div>

        <ModalPdf :pdfPreview="pdfPreview" v-if="this.isVisible" />
        <ModalOrder @removeItemOrder="removeOrder" :order="order" v-if="this.isVisibleOrder" />

    </div>
</div>
</template>

<script>
import ModalPdf from '../ModalPdf.vue'
import ModalOrder from '../ModalOrder.vue'
import pdfvuer from 'pdfvuer';
import tioTotoService from '../../service/data.service';
import store from '@/store/index.js';
import Spinner from '../Spinner.vue'
require('dotenv').config();

export default {
    name: 'file',
    components: {
        ModalPdf,
        pdfvuer,
        Spinner,
        ModalOrder
    },
    data() {
        return {
            pdfSrc: [],
            order: [],
            pdfPreview: "",
            isVisible: true,
            isVisibleOrder: true,
            page1: 1,
            page2: 2,
            page3: 3,
            page4: 4
        };
    },
    methods: {

        handleFileChange: async function (event) {
            try{
                this.validationDataUser();
                if (this.validatePdfReload()) {
                    store.dispatch("setSpinner", true);
                    Array.from(event.target.files).forEach((file, index) => {
                        this.uploadFileS3(file);
                        // this.getPriceByFile(file, index,nemeKey);
                    });
                 

                } else {
                    this.clearInputFile();
                    store.dispatch("setSpinner", false);
                    this.$swal("Tío Toto", "Para cotizar un archivo es necesario completar la forma de impresión.", "info")
                }
            } catch(e){
                this.$swal("Tío Toto", e, "info");
            }
        },
        validationDataUser(){
            if(this.name == null || this.name == ""){
                throw "El nombre no puede estar vacio";
            }
            if(this.lastName == null || this.lastName == ""){
                throw "El apellido no puede estar vacio";
            }
            if(this.phone == null || this.phone == ""){
                throw "El celular no puede estar vacio";
            }
            if(this.email == null || this.email == ""){
                throw "El email no puede estar vacio";
            }
            if(this.emailConfirmation == null || this.emailConfirmation == ""){
                throw "La confirmacion del email no puede estar vacio";
            }
            if(this.email != this.emailConfirmation ){
                throw "Los emails no coinciden";

            }

        },
        uploadFileS3(file) {
            try {
                this.validationFile(file);
                let nameKey = Math.floor(Math.random() * 10001) + "_" + new Date().getTime() + "_" + Math.floor(Math.random() * 10001) + "." + "pdf"
                const s3 = new AWS.S3({
                    accessKeyId: process.env.VUE_APP_API_AWS_ACCESS_KEY_ID,
                    secretAccessKey: process.env.VUE_APP_API_AWS_SECRET_ACCESS_KEY
                });

                const params = {
                    Bucket: process.env.VUE_APP_API_AWS_BUCKET,
                    Key: nameKey,
                    Body: file,
                };

                s3.putObject(params, (err, data) => {
                    if (err) {
                        throw e;
                    } else {
                        this.getPriceByFile(file, nameKey)
                    }
                });

            } catch (error) {
                store.dispatch("setSpinner", false);
                this.$swal("Tío Toto", error, "info");
            }
        },
        validationFile(file) {
            const maxSize = 400 * 1024 * 1024;
            if (file && file.size > maxSize) {
                throw ('El tamaño del archivo excede el límite permitido de 400 MB.');
            }
            if( file && file.type != "application/pdf"){
                throw ('El tipo de archivo permitido es PDF.');
            }
            
        },
        getPriceByFile: async function (file, nameKey) {
            try {
                const formData = new FormData();
                store.dispatch("setSpinner", true);
                formData.append('data', this.generateDataCotizacion(file, nameKey));
                const {
                    status,
                    data
                } = await tioTotoService.getCotizacionByFile(formData);
                file.id = new Date().getTime();
                file.pages = data.pages;
                file.price = data.price;
                file.nameKey = data.nameKey;
                this.pdfSrc.push(file);
                store.dispatch("setSpinner", false);
            } catch (error) {
                if (error != null && error.code != null && error.code == "ERR_NETWORK") {
                    this.$swal("Tío Toto", "No fue posible subir su archivo. Intentelo nuevamente", "info");
                } else {
                    this.$swal("Tío Toto", error , "info");
                }
            } finally {
                this.clearInputFile();
                store.dispatch("setSpinner", false);
            }
        },
        addtoOrder: async function () {
            try {
                if (this.pdfSrc.length != 0 && this.hoja.length > 1 && this.cantidad > 0 && this.formato.length > 1 && this.calidad.length > 1 && this.faz.length > 1 && this.tipoPapel.length > 1 && this.terminado.length > 1) {
                    store.dispatch("setSpinner", true);
                    var obj = new Object();
                    obj.terminado = this.terminado;
                    obj.cantidad = this.cantidad;
                    obj.agrupado = this.agrupado;
                    obj.data = JSON.stringify(this.pdfSrc);
                    const {
                        status,
                        data
                    } = await tioTotoService.getTerminadoAgrupado(JSON.stringify(obj));
                    this.order.push(this.generateDtoOrder(data));
                    this.pdfSrc = [];
                } else {
                    this.$swal("Tío Toto", "Cotice al menos un archivo para agregarlo al pedido.", "info");
                }
            } catch (error) {
                this.$swal("Tío Toto", "Se ha generado un error al agregar los archivos al carrito", "info");
            } finally {
                store.dispatch("setSpinner", false);
            }
        },

        removeFileCotizacion: async function (dataFile) {
            store.dispatch("setSpinner", true);
            await tioTotoService.removeFileCotizacion(JSON.stringify(dataFile));
            this.pdfSrc = this.pdfSrc.filter(item => item.id !== dataFile.id);
            store.dispatch("setSpinner", false);
        },

        // ****************************** FUNCTION UTILS *************************************

        uploadFile() {
            $("#fileInput").click();
        },
        generateDtoOrder(data) {
            var obj = new Object();
            obj.files = this.pdfSrc;
            obj.tamHojas = this.hoja;
            obj.tamHojasDesc = this.hojaDesc;
            obj.formato = this.formato;
            obj.formatoDesc = this.formatoDesc;
            obj.calidad = this.calidad;
            obj.calidadDesc = this.calidadDesc;
            obj.faz = this.faz;
            obj.fazDesc = this.fazDesc;
            obj.terminado = this.terminado;
            obj.terminadoDesc = this.terminadoDesc;
            obj.tipoPapel = this.tipoPapel;
            obj.tipoPapelDesc = this.tipoPapelDesc;
            obj.agrupado = this.agrupado;
            obj.agrupadoDesc = this.agrupadoDesc;
            obj.cantidad = this.cantidad;
            obj.id = this.idPerson;
            obj.idOrder = new Date().getTime();
            obj.totalTerminado = data;
            return obj;
        },
        totalOrder() {
            let sum = 0;
            this.order.forEach(data => {
                Array.from(data.files).forEach(files => {
                    sum = sum + files.price * data.cantidad;
                })
                sum = sum + data.totalTerminado;
            })
            return this.formatearMoneda(sum);
        },
        totalSubOrder() {
            let sum = 0;
            this.pdfSrc.forEach(data => {
                sum = sum + data.price * this.cantidad;
            })
            return this.formatearMoneda(sum);
        },
        createPDF(data) {
            return URL.createObjectURL(data);
        },

        removeOrder(idOrder) {
            this.order = this.order.filter(item => item.idOrder !== idOrder);
            if (this.order.length == 0) {
                setTimeout(() => {
                    jQuery("#modalOrder").modal("toggle");
                }, 100);
            }
        },
        previewPdf(data) {
            this.pdfPreview = data;
            this.isVisible = true;
            setTimeout(() => {
                jQuery("#modalPdfPreview").modal("toggle");
            }, 100);
        },
        previewOrder() {
            if (this.name == "" || this.email == "" || this.lastName == "" || this.phone == "") {
                this.$swal("Tío Toto", "Ingrese los datos del pedido", "info");
            } else if (this.order.length == 0) {
                this.$swal("Tío Toto", "Su pedido no puede estar vacio", "info");
            } else {
                this.isVisibleOrder = true;
                setTimeout(() => {
                    jQuery("#modalOrder").modal("toggle");
                }, 100);
            }
        },
        clearInputFile() {
            const fileInput = this.$refs.fileInput;
            fileInput.value = '';
        },
        generateDataCotizacion(file, nameKey) {
            let obj = new Object();
            obj.tamHojas = this.hoja;
            obj.tamHojasDesc = this.hojaDesc;
            obj.formato = this.formato;
            obj.formatoDesc = this.formatoDesc;
            obj.calidad = this.calidad;
            obj.calidadDesc = this.calidadDesc;
            obj.faz = this.faz;
            obj.fazDesc = this.fazDesc;
            obj.terminado = this.terminado;
            obj.terminadoDesc = this.terminadoDesc;
            obj.tipoPapel = this.tipoPapel;
            obj.tipoPapelDesc = this.tipoPapelDesc;
            obj.agrupado = this.agrupado;
            obj.agrupadoDesc = this.agrupadoDesc;
            obj.cantidad = 1;
            obj.fileExtension = file.name;
            obj.userID = this.idPerson;
            obj.nameKey = nameKey == null || nameKey == '' ? file.nameKey : nameKey;
            obj.fileName = file.name;
            return JSON.stringify(obj)
        },
        formatearMoneda(value) {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        validatePdfReload() {
            if (this.hoja.length > 1 && this.cantidad > 0 && this.formato.length > 1 && this.calidad.length > 1 &&
                this.faz.length > 1 && this.tipoPapel.length > 1 && this.terminado.length > 1 && !(this.terminado == "TRH02" && this.agrupado == "")) {
                return true;
            } else {
                return false;
            }
        },
        reloadPricePDF() {
            if (this.validatePdfReload()) {
                let data = this.pdfSrc;
                this.pdfSrc = [];
                data.forEach((file, index) => {
                    this.getPriceByFile(file);
                })
            }
        },

    },
    watch: {
        hoja() {
            this.reloadPricePDF();
        },
        fomato() {
            this.reloadPricePDF();
        },
        calidad() {
            this.reloadPricePDF();
        },
        faz() {
            this.reloadPricePDF();
        },
        tipoPapel() {
            this.reloadPricePDF();
        },
        terminado() {
            this.reloadPricePDF();
        },
        agrupado() {
            this.reloadPricePDF();
        },
    },
    computed: {
        hoja: {
            get() {
                return this.$store.state.dataOrder.send.hojas;
            },
        },
        hojaDesc: {
            get() {
                return this.$store.state.dataOrder.send.hojasDesc;
            },
        },
        formato: {
            get() {
                return this.$store.state.dataOrder.send.formato;
            },
        },
        formatoDesc: {
            get() {
                return this.$store.state.dataOrder.send.formatoDesc;
            },
        },
        calidad: {
            get() {
                return this.$store.state.dataOrder.send.calidad;
            },
        },
        calidadDesc: {
            get() {
                return this.$store.state.dataOrder.send.calidadDesc;
            },
        },
        faz: {
            get() {
                return this.$store.state.dataOrder.send.faz;
            },
        },
        fazDesc: {
            get() {
                return this.$store.state.dataOrder.send.fazDesc;
            },
        },
        tipoPapel: {
            get() {
                return this.$store.state.dataOrder.send.tipoPapel;
            },
        },
        tipoPapelDesc: {
            get() {
                return this.$store.state.dataOrder.send.tipoPapelDesc;
            },
        },
        terminado: {
            get() {
                return this.$store.state.dataOrder.send.terminado;
            },
        },
        terminadoDesc: {
            get() {
                return this.$store.state.dataOrder.send.terminadoDesc;
            },
        },
        agrupado: {
            get() {
                return this.$store.state.dataOrder.send.agrupado;
            },
        },
        agrupadoDesc: {
            get() {
                return this.$store.state.dataOrder.send.agrupadoDesc;
            },
        },
        portada: {
            get() {
                return this.$store.state.dataOrder.send.portada;
            },
        },
        cantidad: {
            get() {
                return this.$store.state.dataOrder.send.cantidad;
            },
        },
        name: {
            get() {
                return this.$store.state.person.name;
            }
        },
        lastName: {
            get() {
                return this.$store.state.person.lastName;
            }
        },
        phone: {
            get() {
                return this.$store.state.person.phone;
            }
        },
        email: {
            get() {
                return this.$store.state.person.email;
            }
        },
        emailConfirmation: {
            get() {
                return this.$store.state.person.emailConfirmation;
            }
        },
        idPerson: {
            get() {
                return this.$store.state.person.id;
            }
        },
    }
};
</script>

<style>
.page {
    height: auto !important;
    width: auto !important;
}

.pdf-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.pdf-page {
    flex: 1;
    max-width: 60%;
}

.pdf-page-vertical {
    flex: 1;
    max-width: 28%;
}

.container-pdf {
    margin: auto;
}

.pdf-height {
    min-height: 330px !important;
}
</style>
