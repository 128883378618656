<template>
    <div>
        <SectionHead />
    </div>
</template>
  
<script>
import SectionHead from '@/components/order/SectionRedes.vue'

export default {
    name: 'index',
    components: {
        SectionHead
    },
    mounted() {
    },
    data() {
        return {
        }
    },
    computed: {

    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  