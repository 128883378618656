<template>
<div class="row">
    <section class="mt-2">
        <div class="container pb-2">
            <div class="row justify-content-center">
                <div class="col-md-9">
                    <h3 class="title-precios">Preguntas Frecuentes</h3>
                </div>
                <div class="col-md-12">
                    <section class="mt-2">
				<div class="container pt-3 pb-2">
                    <div class="row justify-content-center">
                        <div class="col-md-9" style="font-size:18px;" v-for="data in frequentQuestion">
                            <div class="mt-4">
                                <h6 class="text-start td-title" style="font-size:18px;color:#444444"><i class="fas fa-question-circle"></i> 
                                    {{data.pregunta}} </h6>
                                <h6 class="text-start td-subTitle" style="font-size:15px;color:grey"> 
                                    {{data.cuerpo}} </h6>
                            </div>
                        </div>
                    </div>
				</div>
			</section>
                </div>
            </div>
        </div>
    </section>

</div>
</template>

<script>
import tioTotoService from '../service/data.service';
import Spinner from './Spinner.vue'
import store from '@/store/index.js';

export default {
    name: 'file',
    components: {
        Spinner,
    },
    data() {
        return {
            frequentQuestion: [],
        };
    },
    mounted() {
        this.getFrequentQuestion();
    },
    methods: {
        getFrequentQuestion: async function (file, index) {
            try {
                store.dispatch("setSpinner", true);
                const {
                    status,
                    data
                } = await tioTotoService.frequentQuestion();
                this.frequentQuestion = data;
            } catch (error) {
                this.$swal("Tío Toto", error, "info");
            } finally {
                store.dispatch("setSpinner", false);
            }
        },
        formatearMoneda(value) {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getTextoFaz(faz) {
            if (faz == "DOBLE FAZ") {
                return "CARILLA";
            } else {
                return "HOJA";
            }
        }
    },
    computed: {

    }
};
</script>

<style>

.tablePrice{
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

</style>
