<template>
    <div>
        <div v-show="this.loading" id="spinner" style="position: fixed; z-index: 9999; top: 0px; left: 0px; width: 100%; height: 100%; background: grey; opacity: 0.5;">
              <button class="spinner-btn">
                  <span class="spinner"></span>
                  <span class="btn__text">Cargando...</span>
              </button>
        </div>
    </div>
  </template>
  
  <script>

  export default {
    name: 'BaseLayout',
    components: {},
    mounted() {
    },
    computed:{
      loading(){
        return this.$store.state.loadingFile;
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>