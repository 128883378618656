<template>
    <div class="card section-item-order-data-container">
        <div class="row justify-content-center section-personal-data">
            <div class="col-md-12 text-center mt-2 pb-0">
                <h5 class="text-start mb-0 title-landing"><i class="fa-solid fa-user icon-option"></i>
                    Datos del Pedido</h5>
                            <ModalPdf/>
            </div>
        </div>
        <hr>
            <div class="row justify-content-start section-personal-data">
            <div class="col-md-12">
                <div class="row justify-content-start">
                    <div class="col-md-6">
                        <label for="emailExample" class="text-gray-600 small">Nombre
                        </label>
                        <input type="text" placeholder="" v-model="name" id="nombre" aria-label="nombre"
                            class="form-control form-control-solid send-data">
                    </div>
                    <div class="col-md-6">
                        <label for="emailExample" class="text-gray-600 small">Apellido</label>
                        <input type="text" placeholder="" v-model="lastName" id="apellido" aria-label="apellido"
                            class="form-control form-control-solid send-data">
                    </div>

                    <div class="col-md-6">
                        <label for="emailExample" class="text-gray-600 small">Celular</label>
                        <input type="number" placeholder="" v-model="phone" id="celular" aria-label="celular"
                            class="form-control form-control-solid send-data" onkeypress="isNumber(event)">
                    </div>
                    <div class="col-md-6">
                        <label for="emailExample" class="text-gray-600 small">Email</label>
                        <input type="text" oncopy="return false" v-model="email" onpaste="return false" placeholder="" id="email"
                            aria-label="email" class="form-control form-control-solid send-data">
                    </div>
                    <div class="col-md-6">
                        <label for="emailExample" class="text-gray-600 small">Confirmaci&oacute;n Email</label>
                        <input type="text" oncopy="return false" v-model="emailConfirmation" onpaste="return false" placeholder="" id="emailConfirm"
                            aria-label="email" class="form-control form-control-solid send-data">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Header',
    components:{},
    data(){
        // return{
        //     name: this.$store.state.person.name,
        //     lastName: this.$store.state.person.lastName,
        //     phone: this.$store.state.person.phone,
        //     email: this.$store.state.person.email,
        //     emailConfirmation: this.$store.state.person.emailConfirmation
        // }
    },
    mounted() {
        this.generateID();
    },
    methods: {
        generateID(){
            this.$store.dispatch("person/setID", new Date().getTime());
        }
    },
    computed: {
        name:{
            set(value) {
                this.$store.dispatch("person/setName", value);
            }
        },
        lastName:{
            set(value) {
                this.$store.dispatch("person/setLastName", value);
            }
        },
        phone:{
            set(value) {
                this.$store.dispatch("person/setPhone", value);
            }
        },
        email:{
            set(value) {
                this.$store.dispatch("person/setEmail", value);
            }
        },
        emailConfirmation:{
            set(value) {
                this.$store.dispatch("person/setEmailConfirmation", value);
            }
        }
    },
    props: {
        step: String,
    }
}
</script>

<style lang="css" scoped></style>
