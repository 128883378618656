<template>
    <div class="col-md-9 text-center mt-4">
        <h2 style="color:#e4a11b">Pedido Incompleto <i class="fas fa-exclamation-circle"></i></h2>
    </div>
    <div class="col-md-9 text-center mt-4 mb-4">
        <h5 style="color:#727272">Se rechazó el pago a través de mercado pago.</h5>
    </div>
    <div class="col-md-9 text-center mt-3 mb-4">
        <a class="btn btn-primary btn-enviar-cotizar lift" style="background-color: rgb(5 56 132);border: none;font-size: 21px;color: white;" @click="redirect()">Nuevo Pedido <i class="fas fa-print"></i></a>
    </div>
</template>

<script>

import tioTotoService from '../../service/data.service';

export default {
    name: 'Section',
    props: {
    },
    mounted(){
        this.sendNotification();
    },
    methods:{
        redirect() {
            location.href = '/';
        },
        sendNotification: async function (){
            try {
                let urlString = window.location.search;
                let obj = new Object();
                obj.id = new URLSearchParams(urlString).get("external_reference");
                await tioTotoService.sendNotification(obj);
            } catch (error) {
                this.$swal("Tío Toto","Se ha generado un error al enviar la notificacion por email","info");
            }
        }
    }

}
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
