<template>
<div class="modal fade" id="modalOrder" tabindex="-1" role="dialog" aria-labelledby="modalDireccion" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" style="padding: 0px 15px;">
            <div class="modal-header">
                <div class="row h-100 p-0 w-100 text-center">
                    <div class="col-md-12 my-auto title-ps pt-2 title-modal" style="font-size: 23px; color: #053884;"><b>Detalle del pedido</b></div>
                </div>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <div class="row pb-2 row-order">
                        <div class="col-md-12 text-start mb-2 head-order">Datos del Pedido </div>
                        <div class="col-md-4" style="font-size: 14px;">Nombre: <b>{{this.name}}</b></div>
                        <div class="col-md-4" style="font-size: 14px;">Apellido: <b>{{this.lastName}}</b></div>
                        <div class="col-md-4" style="font-size: 14px;">Celular: <b>{{this.phone}}</b></div>
                        <div class="col-md-4" style="font-size: 14px;">Email: <b>{{this.email}}</b></div>
                    </div>
                </div>
                <div class="col-md-12 mt-3" v-for="(dataOrder,index) in this.order" v-key="dataOrder">
                    <div class="row row-order">
                        <div class="col-md-12 text-start mb-2 head-order">Orden #{{index+1}} ( {{this.totalSubOrder(dataOrder)}} )</div>
                        <div class="col-md-4" style="font-size: 14px;">Tamaño: <b>{{this.getHoja(dataOrder.tamHojas)}}</b></div>
                        <div class="col-md-4" style="font-size: 14px;">Formato: <b>{{this.getFormato(dataOrder.formato)}}</b></div>
                        <div class="col-md-4" style="font-size: 14px;">Calidad: <b>{{this.getCalidad(dataOrder.calidad)}}</b></div>
                        <div class="col-md-4" style="font-size: 14px;">Faz: <b>{{this.getFaz(dataOrder.faz)}}</b></div>
                        <div class="col-md-4" style="font-size: 14px;">Tipo Papel: <b>{{this.getPapel(dataOrder.tipoPapel)}}</b></div>
                        <div class="col-md-4" style="font-size: 14px;">Terminado: <b>{{this.getTerminado(dataOrder.terminado)}}</b></div>
                        <div class="col-md-4" style="font-size: 14px;">Anillado: <b>{{this.getAGrupado(dataOrder.agrupado)}}</b></div>
                        <div class="col-md-4" style="font-size: 14px;">Cantidad de Juegos: <b>{{dataOrder.cantidad}}</b></div>

                        <ul clas="mt-2">
                            <li class="ms-4 mt-2" v-for="dataFile in dataOrder.files" v-key="dataFile" style="font-weight:500">{{ dataFile.name  }} <a style="color:#578557"> ({{this.formatearMoneda(dataFile.price * dataOrder.cantidad)}}) </a> </li>
                            <li class="ms-4 mt-2" v-if="dataOrder.agrupado == 'A'" style="font-weight:500">Anillado <a style="color:#578557"> ({{this.formatearMoneda(dataOrder.totalTerminado)}}) </a></li>
                        </ul>

                        <div class="col-md-12 mb-3">
                            <div class="row align-items-end justify-content-end">
                                <div class="col-md-2 ps-0" style="cursor: pointer;">
                                    <a @click="removeOrder(dataOrder)" class="btn btn-primary lift w-100 btn-eliminar">Eliminar <i class="fa-solid fa-trash lift"></i></a>
                                </div>
                            </div> 
                        </div>     
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <div class="row row-order">
                        <ul clas="mt-2">
                            <li class="ms-4 mt-2" style="font-weight:500">Bolsa <a style="color:#578557"> ({{this.formatearMoneda(40)}}) </a></li>  
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn lift lift-sm" style="background-color:rgb(149, 193, 35);color:white" type="button" @click="sendOrder()">Pagar <label v-if="this.totalOrder() != 0"> {{this.totalOrder()}}</label> <label v-else> $0</label></button>
                <button class="btn lift lift-sm" style="background-color:grey;color:white" type="button" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import tioTotoService from '../service/data.service'
import store from '@/store/index.js';

export default {
    components: {},
    name: 'ModalOrder',
    methods: {
        getPriceBolsa(){
            let bolsa = false;
            this.order.forEach(data => {
                if(data.terminado == "TRH01"){
                    bolsa =  true;
                }
            })
            return bolsa;
        },
        sendOrder: async function () {
            try {
                store.dispatch("setSpinner", true);
                var objData = new Object();
                objData.personData=this.generatePerson();
                objData.order = this.order;
                const {status,data} = await tioTotoService.sendOrder(JSON.stringify(objData));
                location.href=data.initPoint;
            } catch (error) {
                this.$swal("Tío Toto", error, "info");
            } finally {
                store.dispatch("setSpinner", false);
            }
        },
        generatePerson(){
            var objData = new Object();
            objData.name=this.name;
            objData.lastName=this.lastName;
            objData.email=this.email;
            objData.phone=this.phone;
            objData.idPerson=this.idPerson;
            return objData;
        },
        removeOrder(order) {
            this.$emit('removeItemOrder', order.idOrder);
        },
        formatearMoneda(value) {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        totalOrder() {
            let sum = 0;
            let terminado = 0;
            this.order.forEach(data => {
                terminado = terminado + data.totalTerminado;
                Array.from(data.files).forEach(files => {
                    sum = sum + files.price * data.cantidad;
                })
            })
           
            return this.formatearMoneda(sum + terminado + 40) ;
        },
        totalSubOrder(data) {
            let sum = 0;
            data.files.forEach(dataFile => {
                sum = sum + dataFile.price * data.cantidad;
            })
            return this.formatearMoneda(sum + data.totalTerminado);
        },
        getHoja(data) {
            switch (data) {
                case "TH01":
                    return "A4 - 21X29.7(cm)"
                case "TH02":
                    return "OFICIO - 21.6X35.6(cm)"
                case "TH04":
                    return "A3 - 29.7X42(cm)"
                case "TH05":
                    return "A5 - 14.8X21(cm)"
            }
        },
        getFormato(data) {
            switch (data) {
                case "FH01":
                    return "1 X CARILLA"
                case "FH02":
                    return "2 X CARILLA"
                case "FH03":
                    return "4 X CARILLA"
            }
        },
        getCalidad(data) {
            switch (data) {
                case "CH01":
                    return "COLOR LASER"
                case "CH02":
                    return "COLOR CHORRO A TINTA"
                case "CH03":
                    return "BLANCO Y NEGRO"
                case "CH04":
                    return "BLANCO Y NEGRO PRESENTACIONES (TESIS)"
            }
        },
        getFaz(data) {
            switch (data) {
                case "FH01":
                    return "SIMPLE FAZ"
                case "FH02":
                    return "DOBLE FAZ"
            }
        },
        getPapel(data) {
            switch (data) {
                case "TIH01":
                    return "COMUN - 75 GRAMOS"
                case "TIH01":
                    return "ILUSTRACIÓN - 150 GRAMOS"
                case "TIH01":
                    return "ILUSTRACIÓN - 250 GRAMOS"
            }
        },
        getTerminado(data) {
            switch (data) {
                case "TRH02":
                    return "ANILLADO"
                case "TRH01":
                    return "SUELTO"
            }
        },
        getAGrupado(data) {
            switch (data) {
                case "":
                    return "-"
                case "I":
                    return "INDIVIDUAL"
                case "A":
                    return "AGRUPADO"
            }
        },
    },
    computed: {
        name:{
            get() {
                return this.$store.state.person.name;
            }
        },
        lastName:{
            get() {
                return this.$store.state.person.lastName;
            }
        },
        phone:{
            get() {
                return this.$store.state.person.phone;
            }
        },
        email:{
            get() {
                return this.$store.state.person.email;
            }
        },
        idPerson:{
            get() {
                return this.$store.state.person.id;
            }
        },

    },
    props: {
        order: Object,
        id: String
    }
}
</script>

<style lang="css" scoped>
.head-order{
    color: rgb(5, 56, 132);
    font-size: 20px;
    background-color: #dbdbdb;
    font-weight:600;
}

.row-order{
   box-shadow: rgba(33, 40, 50, 0.15) 0px 0.15rem 1.75rem 0px;
   border: 1px solid rgba(33, 40, 50, 0.125);
}
.btn-eliminar{
    background-color: #959595 !important;
    border: none;
    position: relative;
    cursor:pointer !important;font-size: 14px;
}

</style>
