<template>
    <div class="card section-item-order-data-container mb-4" style="margin-top:25px">
        <div class="col-md-12 text-center mt-1 pb-0">
            <h5 class="text-start mb-0 title-landing"> <i class="fa-solid fa-print  icon-option"></i>
                Forma de Impresión</h5> 
                <hr class="mb-0">
        </div>
        <div class="col-md-12">
            <div class="row justify-content-center mt-1">
              
                    <div class="col-md-6 mt-3">
                      
                        <div class="select">
                            <select class="select-text" v-model="hoja" @change="putTamHoja" ref="selectTamHoja">
                                <option selected value="" disabled>Seleccione..</option>
                                <option v-for="data in this.tamHojaList" :value="data.codigo">{{data.nombre}} <label v-if="data.descripcion">- {{data.descripcion}}</label></option>
                            </select>
                            <label class="select-label">Tamaño de la Hoja</label>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div class="select">
                            <select class="select-text" @change="putFormato" v-model="formato" ref="selectFormato">
                                <option selected value="" disabled>Seleccione..</option>
                                <option v-for="data in this.formatoList" :value="data.codigo">{{data.nombre}}  <label v-if="data.descripcion.length >1">- {{data.descripcion}}</label></option>
                            </select>
                            <label class="select-label">Formato</label>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div class="select">
                            <select class="select-text" @change="putCalidad" v-model="calidad" ref="selectCalidad">
                                <option selected value="" disabled>Seleccione..</option>
                                <option v-for="data in this.calidadList" :value="data.codigo">{{data.nombre}} <label v-if="data.descripcion.length >1">- {{data.descripcion}}</label></option>
                            </select>
                            <label class="select-label">Calidad</label>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3 ">
                        <div class="select">
                            <select class="select-text" @change="putFaz" v-model="faz" ref="selectFaz">
                                <option selected value="" disabled>Seleccione..</option>
                                <option v-for="data in this.fazList" :value="data.codigo">{{data.nombre}} <label v-if="data.descripcion.length >1">- {{data.descripcion}}</label></option>
                            </select>
                            <label class="select-label">Faz</label>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3 ">
                        <div class="select">
                            <select class="select-text" @change="putTipoPapel" v-model="tipoPapel" ref="selectPapel">
                                <option selected value="" disabled>Seleccione..</option>
                                <option v-for="data in this.tipoPapelList" :value="data.codigo">{{data.nombre}} <label v-if="data.descripcion.length >1">- {{data.descripcion}}</label></option>
                            </select>
                            <label class="select-label">Tipo Papel</label>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div class="select">
                            <select class="select-text" @change="putTerminado" v-model="terminado" ref="selectTerminado">
                                <option selected value="" disabled>Seleccione..</option>
                                <option v-for="data in this.terminadoList" :value="data.codigo">{{data.nombre}}</option>
                            </select>
                            <label class="select-label">Terminado</label>
                        </div>
                    </div>
            </div>

            <div class="row justify-content-start mt-2" v-if="this.terminado == 'TRH02'">
                <div class="col-md-6 mt-3">
                    <div class="select">
                        <select class="select-text" style="border:2px solid #0A6ACB" @change="putAgrupado" v-model="agrupado" id="selectAgrupado" ref="selectAgrupado">
                            <option selected value="" disabled>Seleccione..</option>
                            <option value="I">Individual</option>
                            <option value="A">Agrupado</option>
                        </select>
                        <label class="select-label">Anillado</label>
                    </div>
                </div>
                <!-- <div class="col-md-6 mt-3">
                    <div class="select">
                        <select class="select-text" style="border:2px solid #0A6ACB">
                        <option selected value="" disabled>Seleccione..</option>
                        <option value="BN">Blanco y Negro</option>
                        <option value="C">Color</option>
                        </select>
                        <label class="select-label">Portada</label>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="row justify-content-end mt-1">
            <div class="col-md-4 mt-3 text-center">
                <label for="emailExample" class="text-gray-600 small">Copias</label>
                <input type="number" placeholder="" min="1" id="nombre" aria-label="nombre" v-model="cantidad" class="form-control form-control-solid send-data w-100">
            </div>
        </div>
        </div>
</template>

<script>

import tioTotoService from '../../service/data.service';
import store from '@/store/index.js';


export default {
name: 'Header',
components: { },
mounted() {
    this.getHojasData();
},
methods: {
    getHojasData: async function () {
        try {
            store.dispatch("setSpinner",true);
            const { status, data } = await tioTotoService.getHojas();
            store.dispatch('dataOrder/setListHojas', data);
        } catch (error) {
            this.$swal("Tío Toto","Se ha generado un error al obtener la informacion para cargar el pedido","info");
        } finally{
            store.dispatch("setSpinner",false);
        }
        
    },
    putTamHoja: async function (value) {
        try{
            store.dispatch("setSpinner",true);
            store.dispatch('dataOrder/setResetHojas');
            store.dispatch('dataOrder/setHojas', this.hoja);
            store.dispatch('dataOrder/setHojasDesc', this.$refs.selectTamHoja.options[this.$refs.selectTamHoja.selectedIndex].text);
            const { status, data } = await tioTotoService.getAtributosImpresion(this.hoja);
            store.dispatch('dataOrder/setListFormato', data.formato.length === 0 ? this.getFormatoDefault() : data.formato);
            store.dispatch('dataOrder/setListCalidad', data.calidad);
        } catch (error) {
            this.$swal("Tío Toto","Se ha generado un error al obtener la informacion para cargar el pedido","info");
        } finally{
            store.dispatch("setSpinner",false);
        }
        
    },
    putFormato: async function () {
        store.dispatch('dataOrder/setFormato', this.formato);
        store.dispatch('dataOrder/setFormatoDesc', this.$refs.selectFormato.options[this.$refs.selectFormato.selectedIndex].text);
        if (this.calidad != null && this.calidad.length>1) {
            await this.getAtributosImprecionByCalidad();
        }
    },
    putCalidad: async function (value) {
        store.dispatch('dataOrder/setCalidad', this.calidad);
        store.dispatch('dataOrder/setCalidadDesc', this.$refs.selectCalidad.options[this.$refs.selectCalidad.selectedIndex].text);
        if (this.formato != null && this.formato.length>1) {
            await this.getAtributosImprecionByCalidad();
        }
    },
    putFaz: async function (value) {
        store.dispatch('dataOrder/setFaz', this.faz);
        store.dispatch('dataOrder/setFazDesc', this.$refs.selectFaz.options[this.$refs.selectFaz.selectedIndex].text);
    },
    putTipoPapel: async function (value) {
        store.dispatch('dataOrder/setPapel', this.tipoPapel);
        store.dispatch('dataOrder/setPapelDesc', this.$refs.selectPapel.options[this.$refs.selectPapel.selectedIndex].text);
    },
    putTerminado: async function (value) {
        store.dispatch("dataOrder/setAgrupado", "");
        store.dispatch("dataOrder/setPortada", "");
        store.dispatch('dataOrder/setTerminado', this.terminado);
        store.dispatch('dataOrder/setTerminadoDesc', this.$refs.selectTerminado.options[this.$refs.selectTerminado.selectedIndex].text);
    },
    putAgrupado: async function (value) {
        store.dispatch('dataOrder/setAgrupado', this.agrupado);
        store.dispatch('dataOrder/setAgrupadoDesc', this.$refs.selectAgrupado.options[this.$refs.selectAgrupado.selectedIndex].text);
    },

    getAtributosImprecionByCalidad: async function () {
        try{
            store.dispatch("dataOrder/setResetCalidad");
            store.dispatch("setSpinner",true);
            const { status, data } = await tioTotoService.getAtributosImpresionByCalidad(this.hoja, this.calidad);
            store.dispatch('dataOrder/setListFaz', data.faz);
            store.dispatch('dataOrder/setListTipoPapel', data.tipo_papel);
            store.dispatch('dataOrder/setListTerminado', data.terminado);
        } catch (error) {
                this.$swal("Tío Toto","Se ha generado un error al obtener la informacion para cargar el pedido","info");
        } finally {
            store.dispatch("setSpinner",false);

        }
    },

    getFormatoDefault: () => {
        var array = new Array();
        var obj = new Object();
        obj.codigo = "FH01";
        obj.nombre = "1 X CARILLA";
        obj.descripcion= "";
        array.push(obj);
        return array;
    }
},
computed: {
    tamHojaList: {
        get() {
            return this.$store.state.dataOrder.list.listHojas;
        },
    },
    formatoList: {
        get() {
            return this.$store.state.dataOrder.list.listFormato;
        },
    },
    calidadList: {
        get() {
            return this.$store.state.dataOrder.list.listCalidad;
        },
    },
    fazList: {
        get() {
            return this.$store.state.dataOrder.list.listFaz;
        },
    },
    tipoPapelList: {
        get() {
            return this.$store.state.dataOrder.list.listTipoPapel;
        },
    },
    terminadoList: {
        get() {
            return this.$store.state.dataOrder.list.listTerminado;
        },
    },
    hoja:{
        get() {
            return this.$store.state.dataOrder.send.hojas;
        },
        set(value) {
            this.$store.commit("dataOrder/SET_HOJAS", value);
        },
    },
    formato: {
        get() {
            return this.$store.state.dataOrder.send.formato;
        },
        set(value) {
            this.$store.commit("dataOrder/SET_FORMATO", value);
        },
    },
    calidad: {
        get() {
            return this.$store.state.dataOrder.send.calidad;
        },
        set(value) {
            this.$store.commit("dataOrder/SET_CALIDAD", value);
        },
    },
    faz: {
        get() {
            return this.$store.state.dataOrder.send.faz;
        },
        set(value) {
            this.$store.commit("dataOrder/SET_FAZ", value);
        },
    },
    tipoPapel: {
        get() {
            return this.$store.state.dataOrder.send.tipoPapel;
        },
        set(value) {
            this.$store.commit("dataOrder/SET_PAPEL", value);
        },
    },
    terminado: {
        get() {
            return this.$store.state.dataOrder.send.terminado;
        },
        set(value) {
            this.$store.commit("dataOrder/SET_TERMINADO", value);
        },
    },
    agrupado: {
        get() {
            return this.$store.state.dataOrder.send.agrupado;
        },
        set(value) {
            this.$store.commit("dataOrder/SET_AGRUPADO", value);
        },
    },
    portada: {
        get() {
            return this.$store.state.dataOrder.send.portada;
        },
        set(value) {
            this.$store.commit("dataOrder/SET_PORTADA", value);
        },
    },
    cantidad: {
        get() {
            return this.$store.state.dataOrder.send.cantidad;
        },
        set(value) {
            this.$store.commit("dataOrder/SET_CANTIDAD", value);
        },
    },
    
},
watch: {

},
props: {
}
}
</script>

<style lang="css" scoped></style>
