<template>
        <section class="section-head">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 text-center">
                        <!-- <img class="pb-3 img-fluid-mio img-fluid px-xl-4 mt-xxl-n5" width="400" src="img/logoBlanco.png" /> -->
                        <h1 class="texto-titulo mb-1">Centro de Impresiones<br> T&iacute;o
                            Toto</h1>
                        <p class="texto-subtitlo">Envianos tu archivo para imprimir en unos
                            simples pasos</p>
                    </div>
                </div>
            </div>
        </section>
        <div class="row">
            <section class="section-items-step">
                <div class="container-fluid container-head pt-3 pb-2">
                    <div class="row justify-content-center section-items-step-container" style="border:1px solid rgba(0,0,0,.125)">
                            <div class="col-xs-12 col-md-3 d-flex justify-content-center mt-0">
                                <button class="btn lift lift-sm  w-100 button-redes"  type="button" @click="redirect('/')"><i class="fa-solid fa-print"></i> Imprim&iacute; Online </button>
                            </div>
                            <div class="col-12 col-md-3 d-flex justify-content-center mt-5">
                                    <button class="btn lift lift-sm  w-100 button-redes"  type="button" @click="redirect('/price')"><i class="fa-solid fa-credit-card"></i> Lista de Precios </button>
                            </div>
                            <div class="col-12 col-md-3 d-flex justify-content-center mt-5">
                                <button class="btn lift lift-sm  w-100 button-redes" type="button" @click="redirect('https://wa.me/+5491163520103?text=Hola%20tengo%20una%20consulta')"><i class="fa-brands fa-whatsapp"></i> Consultas </button>
                            </div> 
                            <div class="col-12 col-md-3 d-flex justify-content-center mt-5">
                                <button class="btn lift lift-sm  w-100 button-redes" type="button" @click="redirect('https://www.instagram.com/tio_toto_impresiones')"><i class="fa-brands fa-instagram"></i> Instagram </button>
                            </div>
                            <div class="col-12 col-md-3 d-flex justify-content-center mt-5 mb-4">
                                <button class="btn lift lift-sm  w-100 button-redes" type="button" @click="redirect('https://www.facebook.com/tiototo.impresiones')"><i class="fa-brands fa-facebook"></i> Facebook </button>
                            </div>
                    </div>
                </div>
            </section>
        </div>
</template>
  
<script>
export default {
    name: 'Section',
    props: {
        msg: String
    },
    methods:{
        redirect(url){
            window.open(url, '_blank');
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.button-redes{
    background-color: #053884 !important;
    color: white;
    height: 60px;
    font-size: 20px;
    font-weight: 600;
}

</style>
  