<template>
    <div class="modal fade" id="modalPdfPreview" tabindex="-1" role="dialog" aria-labelledby="modalDireccion" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content" style="padding: 0px 15px;">
                <div class="modal-header">
                    <div class="row h-100 p-0 w-100 text-center">
                        <div class="col-md-12 my-auto title-ps pt-4 title-modal" ><b>{{getName()}} - Precio $ {{ this.formatearMoneda(this.pdfPreview.price) }}</b></div>
                    </div>
                </div>
                <div class="modal-body">
                    <!-- <pdfvuer ref="pdfModal" v-if="this.pdfPreview" :src="this.pdfPreview" show-toolbar="true" show-pager="true" zoom="1" pages="all"/> -->
                    <embed v-if="this.pdfPreview" :src="createPDF()" type="application/pdf" zoom="50" width="100%" height="600px">
                </div>
                <div class="modal-footer">
                    <button class="btn lift lift-sm" style="background-color:grey;color:white" type="button" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    </template>

<script>
import pdfvuer from 'pdfvuer';


export default {
    components:{pdfvuer},
    name: 'ModalPdf',
    methods: {
        createPDF(){
            return URL.createObjectURL(this.pdfPreview);
        },
        getName(){
            return this.pdfPreview.name;
        },
        formatearMoneda(value) {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    },
    computed: {
    },
    props: {
        pdfPreview:Object,
        id:String
    }
}
</script>

<style lang="css" scoped></style>
