<template>
    <div>
        <SectionHead />
        <div class="container container-head">
            <div class="row justify-content-center position-views">
                <Error/>
            </div>
        </div>
    </div>
</template>
  
<script>
import SectionHead from '@/components/order/Section.vue'
import Error from '@/components/errorView/ErrorView.vue'

export default {
    name: 'index',
    components: {
        SectionHead,Error
    },
    mounted() {
    },
    data() {
        return {
        }
    },
    computed: {

    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  