<template>
<div class="row">
    <section class="mt-2">
        <div class="container pb-2">
            <div class="row justify-content-center">
                <div class="col-md-9">
                    <h3 class="title-precios">Impresiones</h3>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <tbody id="tbodyListaPrecio">
                            <tr class="pt-2 pb-2" v-for="data in this.priceList" v-key="data">
                                <template v-if="data.tipo_hoja != 'ILUSTRACIÓN' && !(data.calidad == 'COLOR CHORRO A TINTA' || data.calidad == 'COLOR LASER')">
                                    <td class="" style="width:3%;font-size: 9px; vertical-align: middle;"><i class="fas fa-circle"></i></td>
                                    <td style="width:53%;" class="" data-label="Tamaño"> {{ data.tam_hoja }} {{data.faz}} {{data.calidad}} {{data.tipo_hoja}} ( {{data.desc_tipo_hoja}} ) </td>
                                    <td class="text-start" data-label="Precio"> {{formatearMoneda(data.precio)}} POR {{getTextoFaz(data.faz)}} </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="container pt-3 pb-2">
            <div class="row justify-content-center">
                <div class="col-md-9">
                    <h3 class="title-precios">Impresiones en Papel Ilustraci&oacute;n</h3>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <tbody id="tbodyListaPrecio">
                            <tr class="pt-2 pb-2" v-for="data in this.priceList" v-key="data">
                                <template v-if="data.tipo_hoja == 'ILUSTRACIÓN' && !(data.calidad == 'COLOR CHORRO A TINTA' || data.calidad == 'COLOR LASER')">
                                    <td class="" style="width:3%;font-size: 9px; vertical-align: middle;"><i class="fas fa-circle"></i></td>
                                    <td style="width:53%;" class="" data-label="Tamaño"> {{ data.tam_hoja }} {{data.faz}} {{data.calidad}} {{data.tipo_hoja}} ( {{data.desc_tipo_hoja}} ) </td>
                                    <td class="text-start" data-label="Precio"> {{formatearMoneda(data.precio)}} POR {{getTextoFaz(data.faz)}} </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="container pt-3 pb-2">
            <div class="row justify-content-center">
                <div class="col-md-9">
                    <h3 class="title-precios">Impresiones y Fotocopias en Color</h3>
                </div>
                <div class="col-md-12">
                    <table class="table">
                        <tbody id="tbodyListaPrecio">
                            <tr class="pt-2 pb-2" v-for="data in this.priceList" v-key="data">
                                <template v-if="data.calidad == 'COLOR CHORRO A TINTA' || data.calidad == 'COLOR LASER'">
                                    <td class="" style="width:3%;font-size: 9px; vertical-align: middle;"><i class="fas fa-circle"></i></td>
                                    <td style="width:53%;" class="" data-label="Tamaño"> {{ data.tam_hoja }} {{data.faz}} {{data.calidad}} {{data.tipo_hoja}} ( {{data.desc_tipo_hoja}} ) </td>
                                    <td class="text-start" data-label="Precio"> {{formatearMoneda(data.precio)}} POR {{getTextoFaz(data.faz)}} </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="container pt-3 pb-2">
            <div class="row justify-content-center">
                <div class="col-md-9">
                    <h3 class="title-precios">Terminaciones</h3>
                </div>
                <div class="col-md-6">
                    <table class="table">
                        <tbody id="tbodyListaPrecio">
                            <tr class="pt-2 pb-2" v-for="data in this.priceListTerminate" v-key="data">
                                <td class="" style="width:3%;font-size: 9px; vertical-align: middle;"><i class="fas fa-circle"></i></td>
                                <td style="width:53%;" class="" data-label="Tamaño"> {{data.nombre}} {{data.descripcion}} </td>
                                <td class="text-start" data-label="Precio"> {{formatearMoneda(data.precio)}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

</div>
</template>

<script>
import tioTotoService from '../service/data.service';
import Spinner from './Spinner.vue'
import store from '@/store/index.js';

export default {
    name: 'file',
    components: {
        Spinner,
    },
    data() {
        return {
            priceList: [],
            priceListTerminate: [],
        };
    },
    mounted() {
        this.getPriceList();
    },
    methods: {
        getPriceList: async function (file, index) {
            try {
                store.dispatch("setSpinner", true);
                const {
                    status,
                    data
                } = await tioTotoService.listPrice();
                this.priceList = data.listPrice;
                this.priceListTerminate = data.listPriceTerminate;
            } catch (error) {
                this.$swal("Tío Toto", error, "info");
            } finally {
                store.dispatch("setSpinner", false);
            }
        },
        formatearMoneda(value) {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getTextoFaz(faz) {
            if (faz == "DOBLE FAZ") {
                return "CARILLA";
            } else {
                return "HOJA";
            }
        }
    },
    computed: {

    }
};
</script>

<style>

.tablePrice{
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

</style>
