<template>
    <div>
        <SectionHead />
        <div class="container-fluid container-head">
            <div class="row">
                <div class="col-md-4 d-flex flex-column align-self-stretch">
                    <div class="col-md-12">
                            <PersonalData/>
                    </div>
                    <div class="col-md-12">
                        <OrderData/>
                    </div>
                </div>
                <div class="col-md-8 d-flex flex-column align-self-stretch">
                    <File/>
                </div>
             
            </div>
        </div>

    </div>
</template>
  
<script>
import SectionHead from '@/components/order/Section.vue'
import PersonalData from '@/components/order/PersonalData.vue'
import OrderData from '@/components/order/OrderData.vue'
import File from '@/components/order/File.vue'

export default {
    name: 'index',
    components: {
        SectionHead,PersonalData,OrderData,File
    },
    mounted() {
    },
    data() {
        return {
        }
    },
    computed: {

    }
}
</script>
  
<style scoped></style>
  